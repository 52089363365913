<template>
  <div>
    <Grid
      v-if="gridOptions"
      :grid-options="gridOptions"
      :default-params="defaultParams"
    ></Grid>
  </div>
</template>

<script>
import Grid from "../components/Grid"
import getGridOptions from "../components/Grid/gridOptions"
export default {
  components: {
    Grid,
  },
  data() {
    return {
      gridOptions: null,
      defaultParams: {
        related_violations: true,
      },
    }
  },
  beforeMount() {
    const gridOptions = getGridOptions("research")
    const extraCols = [
      {
        headerName: "Linked",
        headerTooltip: "Linked",
        tooltipValueGetter: () => "Linked",
        width: 28,
        valueGetter: ({ data }) => {
          return data.annotation && data.annotation.groups
        },
        cellRenderer: ({ value }) => {
          return value ? "<span class='ag-icon ag-icon-linked'></span>" : ""
        },
        __metadata__: { type: null, editable: false },
      },
      {
        headerName: "Related Violations",
        headerTooltip: "Related Violations",
        tooltipValueGetter: () => "Related Violations",
        field: "related_violation_count",
        width: 28,
        cellStyle: ({ value }) => ({
          color: value > 0 ? "var(--primary)" : "var(--secondary)",
          "font-weight": value > 0 ? "600" : "400",
        }),
        __metadata__: { type: null, editable: false },
      },
    ]
    gridOptions.columnDefs = [...extraCols, ...gridOptions.columnDefs]
    gridOptions.frameworkComponents = {
      ...gridOptions.frameworkComponents,
      PanelLink: "PanelLink",
      PanelUnlink: "PanelUnlink",
      PanelRelated: "PanelRelated",
    }
    gridOptions.sideBar.toolPanels = [
      ...gridOptions.sideBar.toolPanels,
      {
        id: "link",
        labelDefault: "Link",
        labelKey: "link",
        iconKey: "linked",
        toolPanelFramework: "PanelLink",
      },
      {
        id: "unlink",
        labelDefault: "Unlink",
        labelKey: "unlink",
        iconKey: "unlinked",
        toolPanelFramework: "PanelUnlink",
      },
      {
        id: "related",
        labelDefault: "Related",
        labelKey: "related",
        iconKey: "columns",
        toolPanelFramework: "PanelRelated",
      },
    ]
    gridOptions.sideBar.defaultToolPanel = "search"
    this.gridOptions = gridOptions
  },
}
</script>
